<template>
  <div class="tree-div">
    <Tree
      class="left-div"
      :data="treeData"
      @on-check-change="handleTree"
      :load-data="loadData"
      @on-toggle-expand='toggleExpand'
      show-checkbox
      multiple
    ></Tree>
    <div class="right-div">
      <div class="item-title">{{'已设置区域'}}</div>
      <div class="contanier">
        <div class="item" v-for="(item,index) in data" :key="index">
          <div class="title">{{item.regionWholeAddr || '未知'}}</div><Icon @click="deleteRow(item)" class="icon" type="ios-close" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
      code:{
          type:String,
          default:()=>{
              return ''
          }
      },
      name:{
          type:String,
          default:()=>{
              return ''
          }
      },
      data:{
        type:Array,
        default:()=>{
          return []
        }
      },
      deleteId:{
        type:String,
        default:()=>{
          return ''
        }
      }
  },
  data() {
    return {
      treeData: [],
      custGlobalId: "",
      oemCode: "", //要从缓存拿
      orgName: '',  //社区名称
    };
  },
  created() {
    this.custGlobalId = parent.vue.loginInfo.userinfo.custGlobalId;
    this.oemCode = parent.vue.oemInfo.oemCode;
    setTimeout(()=>{
      this.getTree(this.code)
    },100) 
  },
  // async mounted() {
  //    await 
  // },
  methods: {
    // 点击展开子节点事件
   toggleExpand(val){

   },

    handleTree(data) {
      let arr = []
      if(data && data.length != 0){
        data.map(item=>{
          if(item.regionType == '0' || item.regionType == '4'){
            arr.push(item)
          }
        })
      }
      data = arr
      this.$emit('handleTree',data,this.treeData)
    },

    getTree(val) {
      this.treeData = [];
      if (val && val.length > 0) {
        this.$get("/gx/pc/organization/selectOrganizationByOrgCodeV2", {
          orgCode: this.code,
        }).then((re) => {
            if (re&&re.code === "200"&&re.dataList!=null) {
              let temp = re.dataList.map((item) => {    
                if(item.child == true){
                   return {
                        title: item.name,
                        orgCode: item.code,
                        orgName: item.name,
                        parentId: item.parentId,
                        orgId: item.id,
                        id:item.id,
                        loading: false,
                        children: [],
                    }; 
                }else{
                   return {
                        title: item.name,
                        orgCode: item.code,
                        orgName: item.name,
                        parentId: item.parentId,
                        orgId: item.id,
                        id:item.id,
                      
                    }; 
                }                    
              });
              this.treeData = temp;
            }
          });
      }
    },
    loadData(item, callback) {
      let params = {
        oemCode: parent.vue.oemInfo.oemCode,
        orgId: Number(item.id) || item.parentId,
        parentId: item.regionId ? item.regionId : 0,
        regionTypeFilter: ''
      };
      if (item.regionType === '2' || item.regionType === '3') {
        this.$get('/gx/pc/house/selectHouseTreeV2', {
          parentId: item.id,
        }).then(res => {
          if (res.code == '200' && res.dataList !== null) {
            let arr2 = res.dataList.map((e) => {  //liang
              if (e.regionType === "0") {
                //4：门牌号(无小区无楼栋)
                return {
                  title: e.name,
                  regionId: e.id,
                  oemCode: parent.vue.oemInfo.oemCode,
                  code: e.code,
                  parentId: e.parentId,
                  regionType: e.regionType,
                  orgCode: item.orgCode,
                  ...e
                }
              }
            })
            item.children = arr2
            callback(item.children);
          }
        })
      } else {
        this.$get("/gx/pc/region/selectRegionTreeV2", params)
            .then((res) => {
              if (res.code === "200" && res.dataList != null) {
                let arr = res.dataList.map((i) => {
                  if (i.regionType === "0") {
                    //4：门牌号(无小区无楼栋)
                    return {
                      title: i.name,
                      regionId: i.id,
                      oemCode: parent.vue.oemInfo.oemCode,
                      code: i.code,
                      parentId: i.parentId,
                      regionType: i.regionType,
                      orgCode: item.orgCode,
                      ...i
                    }
                  } else if (i.regionType === "4") {
                    //4：门牌号(无小区无楼栋)
                    return {
                      title: i.name,
                      regionId: i.id,
                      oemCode: parent.vue.oemInfo.oemCode,
                      code: i.code,
                      parentId: i.parentId,
                      regionType: i.regionType,
                      orgCode: item.orgCode,
                      ...i
                    }
                  } else if (i.regionType === "3") {     //fuck
                    //1：小区 2：小区的楼栋 3：单独的楼栋"
                    return {
                      title: i.name,
                      regionId: i.id,
                      oemCode: parent.vue.oemInfo.oemCode,
                      code: i.code,
                      parentId: i.parentId,
                      regionType: i.regionType,
                      orgCode: item.orgCode,
                      child: item.child,
                      ...i
                    };
                  } else if (i.regionType === "2") {
                    return {
                      title: i.name,
                      regionId: i.id,
                      oemCode: parent.vue.oemInfo.oemCode,
                      code: i.code,
                      parentId: i.parentId,
                      regionType: i.regionType,
                      orgCode: item.orgCode,
                      child: item.child,
                      ...i
                    };
                  } else if (i.regionType === "1") {
                    return {
                      title: i.name,
                      regionId: i.id,
                      oemCode: parent.vue.oemInfo.oemCode,
                      code: i.code,
                      parentId: i.parentId,
                      regionType: i.regionType,
                      orgCode: item.orgCode,
                      child: item.child,
                      ...i
                    };
                  }
                });
                arr = arr.map(item => {
                  if (item.child === true ) {
                    return {
                      loading: false,
                      children: [],
                      ...item,
                    }
                  }else {
                    return {
                      ...item
                    }
                  }
                });

                item.children = arr
                callback(item.children);
              }else{
                callback(item.loading = false)
                this.$Message.warning({
                  content:'获取数据失败',
                  background:true
                })
              }
            }).catch((err) => {
          console.log(err);
        });
      }
    },
    // 点击了删除按钮
    deleteRow(item){
      this.$emit('deleteRow',item)
      this.getCurrentTitle(item.code,this.treeData)
    },
       //递归树,获取名称
   getCurrentTitle(code,treeData) {
     treeData.map((item,index)=>{
       if(!item.code) item.code = item.orgCode,item.checked = false
       if(item.code == code) {
         treeData.splice(index,1,{
           ...item,
           checked: false,
         })
       }
       else{
         if(item.children && item.children.length != 0 && JSON.stringify(item).indexOf(`"code":"${item.code}"`) != -1) {
           item.checked = false;
           this.getCurrentTitle(code,item.children)
         }
         }
     })
   }, 
   
  },

};
</script>

<style lang="less" scoped>
//编写css
  .tree-div{
      // height:500px;
      overflow-y: scroll;
      display: flex;
      .left-div{
        width: 300px;
        border: 1px solid #cccccc;
      }
      .right-div{ 
        flex: 1;
        min-height: 400px;
        border: 1px solid #cccccc;
        border-left: none;
        .item-title{
          font-size: 14px;
          margin: 10px;
        }
        .contanier{
          width: 100%;
          .item{
            margin: 10px;
            display: flex;
            .icon{
              margin-left: auto;
              margin-right: 10px;
              font-size: 20px;
              cursor: pointer;
              &:hover{
                color: black;
              }
            }
          }
        }
      }
  }
</style>
